.app{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  color: black;
  min-height: 100vh;
}

.app__header{
  min-width: 100%;
  height: auto;
  min-height: 70px;
  background-color: #edd652;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.app__body{

color: black;
 width: 200px;
 border-radius: 4px;
 justify-content: space-between;
 flex-direction: column;
 align-items: center;
 margin-top: 10px;
 display: flex;
 justify-content: center;
}
.app__body__btn{
  background-color: #edd652;
  margin: 30px;
  min-width: 100px;
  height: 20px;
}
.app__footer{
  background-color: #282f39;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  position: absolute;
  bottom: 0;
  padding: 10px;
}
h4{
  font-weight: 100;
  font-size: 16px;
}
 h3{
  font-weight: 100;
  font-size: 17px;
}
.app__body__b{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 14%;
}
.app__img{
  background-color: #282f39;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__img_i{

  margin: 6px;
}
.app__img_s{
  border: 3px solid #edd652 ;
  margin: 6px;
}
.h2{
  font-weight: 100;
  font-size: 18px;
}
.place{
  height: 25px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;;
  border-radius: 4px;
  margin: 2px;
 
}
a{
  color: #282f39;
}
.reserved{
  background-color: rgb(225, 74, 74);
}
.app__img h3{
  font-weight: 100;
  font-size: 17px;
}
.app__hcl{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.formulaire{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.frm{
background-color: #abb4c1;
color: #282f39;
padding: 5px;
}
@media screen and (max-width: 768px) {
  .app__body__b{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 24%;
  } 
}
.item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;

}

.itemO{
min-width: 100px;
margin: 2px;
display: flex;
align-items: center;
justify-content: center;
}
.stat{
  width: 40px;
  height: 20px;
}
.adm{
  min-width: 100%;
}
.choix{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
}