.logCnt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:#edd652;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logBlog {
  height: 65vh;
  width: 75vh;
  background-color: white;
  margin: 5% auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
}
.logAvatar {
  height: 110px;
  width: 110px;
  border: 4px solid #edd652;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bt {
  background-color: #edd652;
  height: 35px;
  width: 100px;
  color: #282f39;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
 a{
    color:white;
    text-decoration:none;
}